import { useState } from "react";
import Slider from "react-slick";
import photo1 from '../assets/images/home1.webp';
import photo2 from '../assets/images/home2.webp';
import photo3 from '../assets/images/home3.webp';
import photo4 from '../assets/images/home4.webp';
import photo5 from '../assets/images/home5.webp';
import photo6 from '../assets/images/home6.webp';
import photo7 from '../assets/images/home7.webp';
import photo8 from '../assets/images/home8.webp';
import photo9 from '../assets/images/home9.webp';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../assets/css/MyCarousel/my_carousel.css';

export const MyCarousel = () => {
  const media = window.matchMedia("(max-width: 992px)");
  const media2 = window.matchMedia("(max-width: 576px)");
  const [photoBig, setPhotoBig] = useState(false);
  const [initialPhoto, setInitialPhoto] = useState(null);

  const settings = {
    dots: true,
    speed: 1500,
    slidesToShow: media.matches ? media2.matches ? 1 : 2 : media2.matches ? 1 : 3,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1000,
  };

  const images = [photo1, photo2, photo3, photo4, photo5, photo6, photo7, photo8, photo9];

  return (
    <>
      <div className="text-center">
      <Slider {...settings}>
        {
            images.map((img,index) => {
                return (
                    <div onDoubleClick={()=>{
                      setInitialPhoto(index);
                      setPhotoBig(true);
                  }} key={index}>
                        <img src={img} className="my_carousel_img" alt="photo" />
                    </div>
                );
            })
        }
      </Slider>
    </div>
    {
      photoBig ? <div className="photo_open" onClick={(e)=>{
          if(e.target.getAttribute('class') === 'photo_open'){
              setPhotoBig(false);
          }
      }}>
      <div className="close_photo" onClick={()=>{
          setPhotoBig(false);
      }}>X</div>
      <div className="photo_prev" onClick={()=>{
          if(initialPhoto !== 0){
              setInitialPhoto(initialPhoto-1);
          }else{
            setInitialPhoto(images.length-1);
          }
      }}>&lt;</div>
      <img src={images[initialPhoto]} alt="gallery photo" />
      <div className="photo_next" onClick={()=>{
          if(initialPhoto !== images.length-1){
              setInitialPhoto(initialPhoto+1);
          }else{
            setInitialPhoto(0);
          }
      }}>&gt;</div>
  </div> : null
  }
    </>
  );
};