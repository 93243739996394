import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Header } from "./components/Header";
import { Scroll } from "./components/Scroll";
import { Home } from './pages/Home';
import { Footer } from "./components/Footer";
import { Error } from "./pages/Error";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/main.css';

export default function App() {

  if(!localStorage.getItem('lang')){
    localStorage.setItem('lang', 'am');
  };

  return (
    <BrowserRouter>
        <Header />
        <Scroll />
        <div className="content">
        <Routes>
          <Route index element={<Home />} />
          <Route path="*" element={<Error />} />
        </Routes>
        </div>
        <Footer />
    </BrowserRouter>
  );
}