import { Lang } from './Lang';
import '../assets/css/Footer/footer.css';

export const Footer = () => {
    return (
        <>
            <div className="footer">
                <div>
                    <Lang text='copyright' /> | by <a href='#' target='_blank'>Star Code</a>
                </div>
            </div>
        </>
    )
};