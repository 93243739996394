import En from './langs/en.json';
import Ru from './langs/ru.json';
import Am from './langs/am.json';

export const Lang = ({text}) => {
    const curLang = localStorage.getItem('lang');

    const langs = {
        en: En,
        ru: Ru,
        am: Am
    };
    return langs[curLang][text];
};