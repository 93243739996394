import { Lang } from '../components/Lang';
import '../assets/css/Error/error.css';

export const Error = () => {
    return (
        <>
            <div className="error mt-10">
                404
                <div><Lang text='this_page_was_not_found' /></div>
            </div>
        </>
    );
};