import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import { Lang } from './Lang';
import logo from '../assets/images/logo.png';
import en from '../assets/images/en.png';
import ru from '../assets/images/ru.png';
import am from '../assets/images/am.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/Header/header.css';

export const Header = () => {
    const curLang = localStorage.getItem('lang');
    const media = window.matchMedia("(max-width: 992px)");

    return (
        <>
            <Navbar expand='lg' id="header">
      <div className='container-fluid'>
        <Navbar.Brand><img src={logo} alt="logo" className='logo' onClick={()=>window.scrollTo(0,0)} /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" id='nav_toogler' />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className='me-auto mx-auto nav_links'>
            <Link onClick={()=>{
              if(media.matches){
            document.getElementById('nav_toogler').click();
        };
            window.scrollTo(0,0);
            }}><Lang text='home' /></Link>
            <Link onClick={()=>{
              if(media.matches){
            document.getElementById('nav_toogler').click();
        };
            document.getElementById('introduction').scrollIntoView();
            }}><Lang text='introduction' /></Link>
            <Link onClick={()=>{
              if(media.matches){
            document.getElementById('nav_toogler').click();
        };
            document.getElementById('projects').scrollIntoView();
            }}><Lang text='projects' /></Link>
            <Link onClick={()=>{
              if(media.matches){
            document.getElementById('nav_toogler').click();
        };
            document.getElementById('contact_us').scrollIntoView();
            }}><Lang text='contact_us' /></Link>
          </Nav>
          <NavDropdown title={curLang === 'en' ? <><img src={en} alt="en" /> English</> : 
          curLang === 'ru' ? <><img src={ru} alt="ru" /> Русский</> : 
          <><img src={am} alt="am" /> Հայերեն</>} id="basic-nav-dropdown">
              <NavDropdown.Item value="en" onClick={()=>{
                localStorage.setItem('lang', 'en');
                window.location.reload();
              }}><img src={en} alt="en" /> English</NavDropdown.Item>
              <NavDropdown.Item value="ru" onClick={()=>{
                localStorage.setItem('lang', 'ru');
                window.location.reload();
              }}><img src={ru} alt="ru" /> Русский</NavDropdown.Item>
              <NavDropdown.Item value="am" onClick={()=>{
                localStorage.setItem('lang', 'am');
                window.location.reload();
              }}><img src={am} alt="am" /> Հայերեն</NavDropdown.Item>
            </NavDropdown>
        </Navbar.Collapse>
      </div>
    </Navbar>
    </>
    )
};