import home_photo from '../assets/images/home_photo.png';
import { Lang } from '../components/Lang';
import { MyCarousel } from '../components/MyCarousel';
import '../assets/css/Home/home.css';

export const Home = () => {
    return (
        <>
            <div className="mt-6" id='home_photo'>
                <img src={home_photo} alt="photo" />
                <div id="home_photo_title_text">
                    <div id="title">
                        <span><Lang text='home_text1' /></span> <Lang text='home_text2' /> <br /> <span><Lang text='home_text3' /></span> <Lang text='home_text4' />
                    </div>
                </div>
                <div id="home_photo_read_more">
                    <span onClick={()=>{
                            document.getElementById('introduction').scrollIntoView();
                        }}><Lang text='read_more' /></span>
                </div>
            </div>
            <div className="container mt-5" id='introduction'>
                <div className="home_title"><Lang text='introduction' /></div>
                <hr />
                Ֆասթ Պռո ՍՊԸ-ն հիմնադրվել է 2023թ-ին որպես շինարարական ընկերություն և անմիջապես հանձն է առել Արաբկիր համայնքի ամենականաչապատ գոտիներից մեկում կառուցվող  Մալխասյանց բնակելի համալիրի կառուցմանը։ Որպես  շինարարական ոլորտում գործունեություն ծավալող առաջատար ընկերություն Ֆասթ Պռո ընկերությունը մեծապես կաևորում է նոր աշխատատեղերի ստեղծումը և տնտեսության զարգացման մեջ իր ներդրումն ունենալու գաղափարը։
            </div>
            <div className="container mt-10" id='projects'>
                <div className="home_title"><Lang text='projects' /></div>
                <hr />
                2024 թվականին Ֆասթ Պռո ընկերությունը մեկնարկել է Արաբկիր Համայնքում կառուցվող նոր պրեմիում դասի ՝ Մալխասյանց Բնակելի համալիրի շինարարական աշխատանքները։ Համալիրը գտնվում է Մալխասյանց փ․անցուղի 9/4 հասցեում։ Համալիրը կազմված է երկու բնակելի մասնաշենքերից, որոնցից առաջին մասնաշենքի շինաշխատանքները նախատեսվում  է ավարտել 2024 աշնանը, իսկ արդեն 2025 թվականի աշնանը համալիրն ամբողջությամբ կգհանձնվի շահագործման։
                <div className="mt-10">
                    <MyCarousel />
                </div>
            </div>
            <div className="container mt-10 mb-5" id='contact_us'>
                <div className="home_title"><Lang text='contact_us' /></div>
                <hr />
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3048.2326875812064!2d44.50773798602331!3d40.181634797937576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abcfc97f9fc73%3A0x4051426d14d77ded!2zMyDVj9Wl1oDVtdWh1bYg1oPVuNWy1bjWgSwg1LXWgNaH1aHVtiAwMDAx!5e0!3m2!1shy!2sam!4v1723111748594!5m2!1shy!2sam" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <div className="text-center mt-2">
                    ք․ Երևան, Տերյան փ․ 3, բն 11
                    <br />
                    <a href="mailto:fastpro.evn@gmail.com">fastpro.evn@gmail.com</a>
                    <br />
                    <a href="tel:+374">+374</a>
                </div>
            </div>
        </>
    );
};